

@import "../../../scss/ThemeVariables.module.scss";

.privacypolicy {
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	
	background: var(--Card_Background);
	max-height:300px;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 10px var(--Shadow);
	text-align: left;
	z-index: 1000;
	overflow-y: auto;

	.privacycontent {
		h2 {
			font-size: 18px;
			margin-bottom: 10px;
			color: var(--Text_Color);
		}

		

		p {
			font-size: 14px;
			color: var(--Text_Color);
			line-height: 1.5;
		}

		.privacybutton {
			margin-top: 15px;
			padding: 8px 15px;
			background: var(--Button_Color);
			color: var(--Button_Text_Color);
			border: none;
			border-radius: 4px;
			cursor: pointer;
			transition: background 0.3s ease;

			&:hover {
				background: var(--Navbar_Hover_Button_Color);
			}
		}
	}

	@media (max-width: 500px) {
		width: 95%;
		padding: 15px;

		.privacycontent {
			h2 {
				font-size: 10px;
			}

			p {
				font-size: 8px;
			}

			button {
				padding: 6px 12px;
			}
		}
	}
}
